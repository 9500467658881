@import "../../app/variables";

.listKeyValue {
  :global {
    .row {
      background: $color-white;
      border-top: 1px solid $color-grey-light;
      display: flex;
      flex-wrap: nowrap;
      padding: 15px 0;

      &:last-child {
        border-bottom: 1px solid $color-grey-light;
      }
    }

    .key,
    .value {
      width: 50%;
    }

    .key {
      padding-left: 15px;
    }

    .value {
      text-align: right;
      padding-right: 15px;
    }
  }
}
