@import "../../app/variables";

.label {
  border-radius: 4px;
  text-align: center;
  position: relative;
}

.blue {
  background-color: $color-light-blue;
  color: $color-grey-1;
}

.green {
  background-color: $color-light-green;
  color: $color-grey;
}

.normal {
  padding: 4px 12px;
  font-size: 12px;
  line-height: 18px;
}

.big {
  padding: 17px 12px;
  font-size: 14px;
  line-height: 16px;
}
