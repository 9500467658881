@import '~app/mixins';

.link {
  font-size: 14px;
  line-height: 17px;
  font-family: arial, helvetica, sans-serif;
  cursor: pointer;
  display: inline-block;
  outline: none;
  text-decoration: none !important;
  color: $color-grey;
  position: relative;

  input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  &:hover {
    @include primary-color-dark();

    text-decoration: none;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.loading {
    pointer-events: none;
  }

  &.theme:not(.disabled) {
    @include primary-color();

    &:hover {
      @include primary-color-dark();
    }
  }

  &.red:not(.disabled) {
    color: $color-red;

    &:hover {
      color: darken($color-red, 20%);
    }
  }

  &.blue:not(.disabled) {
    color: $color-blue-dark;

    &:hover {
      color: darken($color-blue-dark, 20%);
    }
  }
}
